@import './variables.scss';

.login__page{
  height: 100vh;
  width: 100vw;
  background: radial-gradient(circle, rgba(83,146,238,1) 50%, rgba(26,62,169,1) 100%);
  .login__form_wrapper{
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 1rem;
    padding: 3% 0;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    img{
      width: 50%;
    }
    .login__form{
      @include columnStartCentre;
      h1{
        text-transform: uppercase;
        font-size: 2rem;
      }
      .loginBtn{
        margin: 2% auto;
      }
      .login__alert{
        margin: 2% auto;
        @include alertText;
      }
    }
  }
  @media(max-width: $mobile){
    .login__form_wrapper{
      width: 80%;
      img{
        width: 75%;
      }
      .login__form{
        h1{
          font-size: 1.7rem;
        }
        .loginBtn{
          margin: 5% auto;
        }
        .login__alert{
          margin: 5% auto;
        }
      }
    }
  }
}