@import './variables.scss';

.dash{
  @include columnBetween;
  min-height: 68vh;
  max-width: 100vw;
  color: $text;
  .dash__main{
    background: $background;
    min-height: 68vh;
    width: 100%;
    @include columnStartCentre;
    .form--select{
      width: 40%;
      margin-bottom: 3%;

      @media (max-width: $tabletMax){
        width: 80%;
      }
    }
    .comp__row{
      @include rowBetween;
      width: 80%;
      margin: 1% auto;
      .form--comp{
        width: 30%;
        margin: 0 auto;
      }  
    }
    .dash__data{
      @include columnStartCentre;
      width: 80%;
      margin: 2% auto;
      table{
        @include customTable;
        width: 80%;
        margin-bottom: 4%;;
      }

      .dash__row{
        width: 100%;
        @include rowBetween;
        margin: 0 auto 3% auto;
        .dash__card{
          background-color: #fff;
          padding: 2% 3%;
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
          width: calc(100%/4);
          @include columnStart;
          h3{
            color: $primary;
            margin-bottom: 0;
          }
          h6{
            font-weight: 500;
            margin-top: 0;
            font-size: 1rem;
          }
        }

        @media (max-width: $tabletMax){
          flex-wrap: wrap;
          margin-bottom: 1%;
          .dash__card{
            width: 60%;
            margin: 1% auto;
          }
          &:nth-of-type(2){
            @include columnStartCentre;
          }
        }
      }

      .no__shows{
        color: $error;
      }
      @media (max-width: $mobile){
        .data__cards{
          flex-wrap: wrap;
          .data--card{
            width: 45%;
            margin-bottom: 5%;
          }
        }
      }
    }
    .input--row{
      input{
        width: 2rem;
      }
    }
    .event__comparison{
      @include rowBetweenStart;
      width: 90%;
      margin: 1% auto;
      .compare__event{
        @include columnStartCentre;
        width: 45%;
        .card{
          margin: 2% auto;
          background-color: #fff;
          padding: 2% 3%;
          box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; 
          width: 100%; 
          h3{
            margin: 0 auto;
            span{
              font-weight: 400;
              margin-left: 5%;
              font-size: 1rem;
            }
          }        
        }
        .eventComp__row{
          @include rowBetween;
          margin: 2% auto;
          width: 100%;
          .comp--card{
            background-color: #fff;
            padding: 2% 3%;
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px; 
            width: 30%;   
            h6{
              font-size: 1rem;
              margin: 0 auto;
              span{
                font-weight: 400;
                margin-left: 5%;
                font-size: .8rem;
              }  
            }        
          }
        }
      }
    }
  }
}