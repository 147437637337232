@import './variables.scss';

nav{
  background-color: #fff;
  @include rowBetween;
  width: 100%;
  padding: 2% 3%;
  // border-bottom: 5px solid $primary;
  img{
    width: 30%;
  }
  @media(max-width: $mobile){
    padding: 5% 3%;
    img{
      width: 65%;
    }
  }
}

footer{
  width: 100%;
  @include columnStack;
  // border-top: 1px solid $text;
  p{
    @include rowCenter;
    margin: 0 auto;
  }
}