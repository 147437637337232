@import './variables.scss';

.events__management {
  @include columnBetween;
  min-height: 100vh;
  max-width: 100vw;

  // EVENTS MANAGEMENT PAGE
  .events__main {
    background: $background;
    width: 100%;
    min-height: 80vh;
    @include rowEven;
    flex-wrap: wrap;

    .selection_card {
      padding: 5% 3%;
      border-radius: .5rem;
      background-color: #fff;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      cursor: pointer;
      @include columnStack;
      width: 20%;

      &:hover {
        box-shadow: none;
        transition: 0.3s;
      }

      strong {
        margin-top: 2%;
        color: $primary;
      }
    }

    @media (max-width: $mobile) {
      @include columnStack;

      .selection_card {
        margin: 5% auto;
        width: 40vw;
      }
    }
  }

  // DOWNLOAD PAGE
  .download__main {
    @include columnStartCentre;
    background: $background;
    width: 100%;
    min-height: 80vh;

    h2 {
      width: 80%;
      text-transform: uppercase;
    }

    select {
      height: 2rem;
      border-radius: .5rem;
      border: 1px solid $primary;
      background-color: #fff;
      margin-bottom: 2%;
    }

    h3 {
      span {
        color: $primary;
      }
    }

    table {
      width: 90%;
      margin: 1% auto;
      color: $text;
      @include customTable;

      @media(max-width: $mobile) {
        width: 90%;
        margin: 3% auto;
        table,
        thead,
        tbody,
        th,
        td,
        tr {
          display: block;
        }

        thead th {
          position: absolute;
          top: -9999px;
          left: -9999px
        }

        tr {
          border: solid 1px $text;
        }

        td {
          border: none;
          border-bottom: 1px solid $text;
          position: relative;
          padding-left: 50%;

          &::before {
            position: absolute;
            top: 6px;
            left: 6px;
            width: 45%;
            padding-right: 10px;
            white-space: nowrap;
          }
          &:nth-of-type(1)::before {
            content: "DATE BOUGHT";
          }
          &:nth-of-type(2)::before {
            content: 'FIRST NAME';
          }
          &:nth-of-type(3)::before {
            content: 'LAST NAME';
          }
          &:nth-of-type(4)::before {
            content: "EMAIL";
          }
          &:nth-of-type(5)::before {
            content: "PHONE NUMBER"
          }
          &:nth-of-type(6)::before {
            content: "TICKET TYPE"
          }
          &:nth-of-type(7)::before {
            content: "DELETE"
          }
          &:nth-of-type(8)::before {
            content: "CHANGE EVENT"
          }
          &:nth-of-type(9)::before {
            content: "RESEND TICKET"
          }
          &:nth-of-type(9)::before {
            content: "EDIT"
          }
        }
      }
    }

    .add__form {
      width: 80%;
      margin: 0 auto;
      @include columnStart;

      label {
        margin-top: 1%;
      }

      input[type=text],
      input[type=email] {
        width: 50%;
      }
    }
  }
}