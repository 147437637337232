@import './variables.scss';

.select__main{
  @include columnBetween;
  min-height: 100vh;
  max-width: 100vw;

  .selection{
    background: $background;
    width: 100%;
    min-height: 80vh;
    @include rowEven;
    flex-wrap: wrap;
    .selection_card{
      padding: 3%;
      border-radius: .5rem;
      background-color: #fff;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
      @include columnStack;
      cursor: pointer;
      &:hover{
        box-shadow: none;
        transition: 0.3s;
      }
      strong{
        margin-top: 2%;
        color: $primary;
      }
    }
    @media (max-width: $mobile){
      @include columnStack;
      .selection_card{
        margin: 5%;
        width: 40vw;
      }
    }
  }
}