// PMW colours
$primary: #1A3EA9;
$secondary: #5392EE;
$background: #E6ECF7;
$text: #131b26;
$action: #EB9624;
$error: #E8160C;

// Media breaks
$mobile: 690px;
$tabletMin: 691px;
$tabletMax: 850px;
$ladnsTabletMin: 851px;
$landsTabletMax: 1300px;
$smallLaptopMin: 1301px;
$smallLaptopMax: 1439px;
$smallScreen:1440px;
$smallScreeMax: 1800px;
$regularScreen: 1900px;
$fourK: 2000px;

// flex variables for styling
@mixin columnStart{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

@mixin columnBetween{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

@mixin columnStartCentre{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

@mixin columnStack{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

@mixin columnCenter{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

@mixin rowEven{
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

@mixin rowEvenStart{
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
}

@mixin rowBetween{
    display: flex;
    align-items: center;
    justify-content: space-between;

}

@mixin rowBetweenStart{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

@mixin rowCenter{
    display: flex;
    align-items: center;
    justify-content: center;
}

@mixin rowCenterStart{
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

@mixin rowCenterFlexStart{
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

@mixin alertText{
    color: $error;
    font-weight: 800;
    animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
    font-size: 1.1rem;
    @media (max-width: $mobile){
        font-size: .9rem;
    }
}

@mixin flex($d: row, $j: center, $a: center, $g: 1vw) {
  display: flex;
  flex-wrap: wrap;
  flex-direction: $d;
  justify-content: $j;
  align-items: $a;
  gap: $g;
}

@mixin customTable{
  // table-layout: fixed;
  background-color: #fff;
  border-collapse: collapse;
  color: $text;
  thead{
    border-bottom: 2px solid $text;
    text-align: center;
    text-transform: uppercase;
    font-weight: 600;
  }
  tbody{
    text-align: center;
    tr{
      border-bottom: 1px solid $text;
      word-wrap: break-word;
      &:nth-child(odd){
        background-color: #f2f2f2;
      }
    }
  }
}

// ANIMATIONS
@keyframes shake {

    10%,
    90% {
      transform: translateX(-1px);
    }
  
    20%,
    80% {
      transform: translateX(2px);
    }
  
    30%,
    50%,
    70% {
      transform: translateX(-4px);
    }
  
    40%,
    60% {
      transform: translateX(4px);
    }
  }
  
