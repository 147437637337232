@import './variables.scss';

.create__event{
  @include columnBetween;
  min-height: 100vh;
  max-width: 100vw;
  color: $text;
  .create__event__main{
    background: $background;
    width: 100%;
    min-height: 80vh;
    @include columnStartCentre;
    h1{
      font-size: 2rem;
      text-transform: uppercase;
      width: 80%;
    }
    small{
      font-weight: 600;
      color: $error;
    }
    .create__form{
      width: 80%;
      margin: 2% auto;
      @include columnStart;
      label{
        margin-top: 2%;
        color: $text;
      }
      input{
        background-color: #fff;
        border: none;
        border-radius: .5rem;
      }
      select{
        border: 1px solid $primary;
        height: 2rem;
        border-radius: 0.5rem;
      }
      .btn__loader{
        @include rowCenterFlexStart;
        margin-top: 2%;
      }
    }

    table{
      width: 80%;
      margin: 2% auto;
      @include customTable;
    }
  }
}