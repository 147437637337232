@import './variables.scss';

.checkin__main{
  @include columnBetween;
  min-height: 100vh;
  max-width: 100vw;

  .checkin{
    background: $background;
    width: 100%;
    min-height: 80vh;
    @include columnStartCentre;
    input[type=text]{
      margin: 0 auto 2% auto;
      border-radius: .5rem;
      @media(max-width: $mobile){
        width: 90%;
      }
    }
    table{
      width: 90%;
      margin: 1% auto;
      @include customTable;
      @media(max-width: $mobile){
        margin-bottom: 3%;
        table,
        thead,
        tbody,
        th,
        td,
        tr {
          display: block;
        }

        thead th {
          position: absolute;
          top: -9999px;
          left: -9999px
        }

        tr {
          border: solid 1px $text;
        }

        td {
          border: none;
          border-bottom: 1px solid $text;
          position: relative;
          padding-left: 50%;

          &::before {
            position: absolute;
            top: 6px;
            left: 6px;
            width: 45%;
            padding-right: 10px;
            white-space: nowrap;
          }
          &:nth-of-type(1)::before {
            content: "ORDER ID";
          }
          &:nth-of-type(2)::before {
            content: 'FIRST NAME';
          }
          &:nth-of-type(3)::before {
            content: "LAST NAME";
          }
          &:nth-of-type(4)::before {
            content: "TICKET TYPE";
          }
          &:nth-of-type(5)::before {
            content: "CHECK-IN"
          }
        }
      }
    }
  }
}

.uncheck-attendee-modal {
  @include flex();
  .btn-section {
    @include flex($a: space-between);
    width: 100%;
    button { width: 45%}
  }
}