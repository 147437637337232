@import './variables.scss';


.sbc__tickets{
  @include columnBetween;
  min-height: 100vh;
  max-width: 100vw;
  color: $text;

  .sbc__main{
    background: $background;
    width: 100%;
    min-height: 80vh;
    @include columnStartCentre;
    padding: 2%;
    img{
      width: 15%;
    }

    table {
      width: 90%;
      margin: 1% auto;
      color: $text;
      @include customTable;

      @media(max-width: $mobile) {
        width: 90%;
        margin: 3% auto;
        table,
        thead,
        tbody,
        th,
        td,
        tr {
          display: block;
        }

        thead th {
          position: absolute;
          top: -9999px;
          left: -9999px
        }

        tr {
          border: solid 1px $text;
        }

        td {
          border: none;
          border-bottom: 1px solid $text;
          position: relative;
          padding-left: 50%;

          &::before {
            position: absolute;
            top: 6px;
            left: 6px;
            width: 45%;
            padding-right: 10px;
            white-space: nowrap;
          }
          &:nth-of-type(1)::before {
            content: 'TICKET NAME';
          }
          &:nth-of-type(2)::before {
            content: 'CURRENT PRICE';
          }
          &:nth-of-type(3)::before {
            content: "EDIT PRICE";
          }
        }
      }
    }

  }
}